import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';

export function useBusinesses(type) {
  const { result, loading } = type === 'supplier' ? useQuery(SUPPLIERS_NAMES) : useQuery(RESTAURANTS_NAMES);

  const business = useResult(result, []);
  return {
    businesses: business,
    loading,
  };
}

const SUPPLIERS_NAMES = gql`
  query getSuppliers {
    getSuppliers {
      id
      name
    }
  }
`;

const RESTAURANTS_NAMES = gql`
  query getRestaurants {
    getRestaurants {
      id
      name
    }
  }
`;

export function useBusinessesNew(type) {
  const isSupplier = type === 'supplier';
  const { result, loading } = isSupplier ? useQuery(SUPPLIERS_NAMES_NEW) : useQuery(RESTAURANTS_NAMES_NEW);

  const business = computed(() => {
    if (result.value) {
      if (isSupplier) {
        return result.value.getSuppliersNew?.nodes ?? [];
      } else {
        return result.value.getRestaurantsNew?.nodes ?? [];
      }
    }
    return [];
  });

  return {
    businesses: business,
    loading,
  };
}

const SUPPLIERS_NAMES_NEW = gql`
  query getSuppliers {
    getSuppliersNew {
      nodes {
        id
        name
      }
    }
  }
`;

const RESTAURANTS_NAMES_NEW = gql`
  query getRestaurants {
    getRestaurantsNew {
      nodes {
        id
        name
      }
    }
  }
`;
