import { useTenancy } from '@/modules/auth';
import i18n from '@/imports/startup/client/i18n';
import { computed } from 'vue';

const currenciesCode = {
  ILS: 'ILS',
  USD: 'USD',
};

export function useCurrency() {
  const { currentTenant } = useTenancy();

  const currencyLocaleStyle = computed(() => {
    const currencySymbol = currentTenant?.value?.countryCode === 'IL' ? currenciesCode.ILS : currenciesCode.USD;
    return {
      style: 'currency',
      currency: currencySymbol,
    };
  });

  const currencySymbol = computed(() => {
    return (0).toLocaleString(i18n.locale, currencyLocaleStyle?.value).replace(/\d./g, '').trim();
  });

  return {
    currencyLocaleStyle,
    currencySymbol,
  };
}
