import { useNotification } from '@/modules/core';
import { useQuery, useResult } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';

export function useDocumentValidateClassification(id, options) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(
    DOCUMENT_VALIDATE_CLASSIFICATION_QUERY,
    { documentId: id },
    options
  );

  const classificationValidation = useResult(result);

  onError((err) => {
    console.error('useDocumentValidateClassification', err);
    error();
  });

  return {
    classificationValidation,
    refetch,
    loading,
    onError,
  };
}

export const DOCUMENT_VALIDATE_CLASSIFICATION_QUERY = gql`
  query documentValidateClassification($documentId: ID!) {
    documentValidateClassification(documentId: $documentId) {
      business
      supplier
      documentType
    }
  }
`;
