import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useDocuments(variables) {
  const { error } = useNotification();

  const { result, loading, onError } = useQuery(DOCUMENTS_NEW_QUERY, variables);
  const documentConnection = useResult(result, { nodes: [], totalCount: 0 });
  const totalCount = computed(() => documentConnection.value.totalCount);

  const documents = computed(() => documentConnection.value.nodes);

  onError((err) => {
    console.error('useDocuments', err);
    error();
  });
  return {
    documents,
    loading,
    totalCount,
  };
}

const DOCUMENTS_NEW_QUERY = gql`
  query documentsNew2(
    $businessId: ID
    $pagination: Pagination
    $supplierId: ID
    $sort: Sort
    $fromDate: ISODate
    $toDate: ISODate
    $recordedAtFrom: Timestamp
    $recordedAtTo: Timestamp
    $documentNumber: String
    $types: [DocumentType]
    $recorded: Boolean
    $templateId: ID
    $structureToken: String
  ) {
    documentsNew2(
      businessId: $businessId
      supplierId: $supplierId
      pagination: $pagination
      sort: $sort
      fromDate: $fromDate
      toDate: $toDate
      recordedAtFrom: $recordedAtFrom
      recordedAtTo: $recordedAtTo
      documentNumber: $documentNumber
      types: $types
      recorded: $recorded
      templateId: $templateId
      structureToken: $structureToken
    ) {
      totalCount
      nodes {
        id
        supplierId
        businessId
        source
        recorded
        recordedAt
        issueDate
        templateId
        documentNumber
        type
        replicateOf
        supplier {
          id
          name
        }
        items {
          name
          productId
          quantity
        }
        business {
          id
          name
        }
        createdUser {
          id
          email
          firstName
          lastName
        }
        filePath
        fromEmail
        totalAmount
        createdAt
        createdBy
      }
    }
  }
`;

export function useDocumentsByIds(variables) {
  const { error } = useNotification();

  const { result, loading, onError } = useQuery(DOCUMENTS_BY_IDS_QUERY, variables, () => ({
    enabled: !!variables.value.businessId && !!variables.value.ids?.length,
  }));

  const documentConnection = useResult(result, { nodes: [], totalCount: 0 });
  const totalCount = computed(() => documentConnection.value.totalCount);
  const documents = computed(() => documentConnection.value.nodes);

  onError((err) => {
    console.error('document - useDocumentsByIds', err);
    error();
  });

  return {
    documents,
    loading,
    totalCount,
  };
}

const DOCUMENTS_BY_IDS_QUERY = gql`
  query documents($businessId: ID!, $ids: [ID!]) {
    documentsNew2(businessId: $businessId, ids: $ids) {
      totalCount
      nodes {
        id
        issueDate
        type
        documentNumber
        supplier {
          id
          name
        }
        netAmount
        taxAmount
        totalAmount
        recordedAt
        items {
          reference
        }
        references {
          documentNumber
        }
      }
    }
  }
`;

export function useDocumentsByDocumentNumber(variables) {
  const { error } = useNotification();

  const { result, loading, onError } = useQuery(DOCUMENTS_BY_DOCUMENT_NUMBER_QUERY, variables, () => ({
    enabled:
      !!variables.value.businessId &&
      !!variables.value.supplierId &&
      !!variables.value.documentNumber &&
      !!variables.value.types[0],
    debounce: 300,
  }));

  const documents = computed(() => {
    return result?.value?.documentsNew2?.nodes || [];
  });

  onError((err) => {
    console.error('document - useDocumentsByDocumentNumber', err);
    error();
  });

  return {
    documents,
    loading,
  };
}

export const DOCUMENTS_BY_DOCUMENT_NUMBER_QUERY = gql`
  query documentsNew2(
    $businessId: ID!
    $supplierId: ID
    $types: [DocumentType]
    $documentNumber: String
    $fromDate: ISODate
    $toDate: ISODate
  ) {
    documentsNew2(
      businessId: $businessId
      supplierId: $supplierId
      documentNumber: $documentNumber
      types: $types
      recorded: true
      fromDate: $fromDate
      toDate: $toDate
    ) {
      nodes {
        id
        type
        filePathUrl
        replicateOf
      }
    }
  }
`;

export function useDocumentsByOrderReference(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(DOCUMENTS_BY_SUPPLIER_ID, variables, () => ({
    enabled: !!variables.value.businessId && !!variables.value.supplierId && !!variables.value.orderReference,
  }));

  const documents = computed(() => {
    return result?.value?.documentsNew2?.nodes || [];
  });

  onError((err) => {
    console.error('document - useDocumentsByDocumentNumber', err);
    error();
  });

  return {
    documents,
    loading,
    refetch,
  };
}

const DOCUMENTS_BY_SUPPLIER_ID = gql`
  query documentsNew2($businessId: ID!, $supplierId: ID, $types: [DocumentType]) {
    documentsNew2(
      businessId: $businessId
      supplierId: $supplierId
      types: $types
      recorded: true
      pagination: { limit: 1000, offset: 0 }
      sort: { sortBy: createdAt, orderBy: desc }
    ) {
      nodes {
        id
        orderReference
      }
    }
  }
`;
