import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';
import { computed } from 'vue';

import { useNotification, useLoading } from '@/modules/core';

export function useDocumentTasks(variables, options = {}) {
  const { error } = useNotification();

  const { result, loading: queryLoading, onError, refetch } = useQuery(DOCUMENT_TASKS_QUERY, variables);
  const taskResult = useResult(result, { nodes: [], totalCount: 0 });

  const totalCount = computed(() => taskResult.value.totalCount);

  const documentTasks = computed(() => taskResult.value.nodes);
  onError((err) => {
    console.error('document - useDocumentTasks', err);
    error();
  });

  return {
    documentTasks,
    totalCount,
    refetch,
    loading: options.avoidUsingLoading ? queryLoading : useLoading(queryLoading, variables),
  };
}

export const DOCUMENT_TASKS_QUERY = gql`
  query tasks($businessId: ID, $type: String, $types: [String], $reviewRequired: Boolean, $limit: Int, $offset: Int) {
    tasksNew2(
      domain: "document"
      businessId: $businessId
      completed: false
      type: $type
      types: $types
      reviewRequired: $reviewRequired
      limit: $limit
      offset: $offset
    ) {
      totalCount
      nodes {
        id
        data
        type
        reviewRequired
        createdAt
        activeAt
        businessId
      }
    }
  }
`;
