import { gql } from '@apollo/client/core';
import { DateTime } from 'luxon';

import { STRUCTURE_PARAMS_FIELDS, ITEM_FIELDS, REFERENCE_FIELDS_NEW2 } from '@/modules/documentModal/queries';

const convertDate = (millis) => millis && DateTime.fromMillis(millis).toJSDate();

export const convertDocument = (doc) => ({
  ...doc,
  issueDate: doc.issueDate,
  createdAt: convertDate(doc.createdAt),
  deliveryNotes: doc.deliveryNotes?.map((dn) => ({ ...dn, date: convertDate(dn.date) })),
});

export const DOCUMENT_QUERY_NEW2 = gql`
  ${STRUCTURE_PARAMS_FIELDS}
  ${ITEM_FIELDS}
  ${REFERENCE_FIELDS_NEW2}
  query documentNew2($id: ID!) {
    documentNew2(id: $id) {
      id
      businessId
      isBusinessValid
      supplierId
      issueDate
      orderReference
      deliveryDate
      type
      documentNumber
      allocationNumber
      filePath
      filePathUrl
      structureParams {
        ...StructureParamsFields
      }
      recorded
      isManual
      replicateOf
      hasDeclaration
      locale
      discountRate
      discountAmount
      rounding
      netAmount
      taxableAmount
      nonTaxableAmount
      taxRate
      taxAmount
      totalAmount
      paymentDueDate
      paidAmount
      paymentMethod {
        type
        creditCard {
          type
          number
        }
        bankTransfer {
          bank
          branch
          account
        }
        cheque {
          bank
          branch
          account
          number
          dueDate
        }
      }
      openingBalance
      amountDue
      referencesFromDate
      referencesToDate
      generalCharges {
        name
        amount
      }
      items {
        ...ItemFields
      }
      references {
        ...ReferenceFields
      }
      ocr {
        status
        originalPath
        originalPathUrl
        correctedPath
        correctedPathUrl
        google {
          status
          generalPathUrl
        }
      }
      createdBy
      createdAt
      templateId
      # recordedBy: String
    }
  }
`;

export const QUERY_DOCUMENT_FILE_UPLOAD = gql`
  query documentFileUpload($businessId: ID!, $name: String!, $size: Int) {
    documentFileUpload(businessId: $businessId, name: $name, size: $size) {
      filePath
      url
      fields
    }
  }
`;

export const MUTATION_CREATE_DOCUMENT = gql`
  mutation createDocumentNew2($businessId: ID!, $filePath: String!, $source: String, $fromEmail: String) {
    createDocumentNew2(businessId: $businessId, filePath: $filePath, source: $source, fromEmail: $fromEmail) {
      id
    }
  }
`;

export const MUTATION_UPDATE_DOCUMENT = gql`
  mutation updateDocumentNew2($id: ID!, $data: DocumentUpdateInputNew2!) {
    updateDocumentNew2(id: $id, data: $data) {
      id
    }
  }
`;

export const MUTATION_DELETE_DOCUMENT = gql`
  mutation deleteDocument($id: ID!) {
    deleteDocument(id: $id)
  }
`;

export const MUTATION_RECORD_DOCUMENT = gql`
  mutation recordDocument($id: ID!, $ignoreWarnings: Boolean!) {
    recordDocument(id: $id, ignoreWarnings: $ignoreWarnings)
  }
`;

export const MUTATION_SPLIT_DOCUMENT = gql`
  mutation splitDocumentNew2($id: ID!) {
    splitDocumentNew2(id: $id) {
      id
    }
  }
`;

export const MUTATION_DUPLICATE_DOCUMENT = gql`
  mutation duplicateDocumentNew2($id: ID!) {
    duplicateDocumentNew2(id: $id) {
      id
    }
  }
`;

export const MUTATION_MERGE_DOCUMENTS = gql`
  mutation mergeDocumentsNew2($ids: [ID!]!) {
    mergeDocumentsNew2(ids: $ids) {
      id
    }
  }
`;

export const MUTATION_DERIVE_DOCUMENTS = gql`
  mutation deriveDocumentsNew2($documentPages: [DocumentPageInput!]!) {
    deriveDocumentsNew2(documentPages: $documentPages) {
      id
    }
  }
`;

export const MUTATION_ROTATE_DOCUMENT = gql`
  mutation rotateDocumentNew2($id: ID!) {
    rotateDocumentNew2(id: $id) {
      id
    }
  }
`;

export const MUTATION_RUN_OCR_ON_DOCUMENT = gql`
  mutation runOcrOnDocument($id: ID!) {
    runOcrOnDocument(id: $id)
  }
`;

export const DELIVERIES_NEW_QUERY = gql`
  query documentDeliveriesQueryNew($businessId: ID, $supplierId: ID!, $date: ISODate!) {
    deliveries(businessId: $businessId, supplierId: $supplierId, fromDate: $date, toDate: $date) {
      nodes {
        id
        source {
          type
          ref
        }
      }
    }
  }
`;
