import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';
import { computed } from 'vue';

import { useNotification } from '@/modules/core';

export function useDocumentsAggregationNew3(variables) {
  const { error } = useNotification();

  const { result, loading, onError } = useQuery(DOCUMENTS_AGGREGATION_NEW3, variables);
  const aggregation = computed(() => result.value?.documentAggregationNew3?.nodes ?? []);

  onError((err) => {
    console.error('document - useDocumentsAggregationNew3', err);
    error();
  });
  return {
    aggregation,
    loading,
  };
}

const DOCUMENTS_AGGREGATION_NEW3 = gql`
  query documentAggregationNew3(
    $businessId: ID
    $fromDate: ISODate
    $toDate: ISODate
    $recorded: Boolean
    $groupBy: [DocumentAggregationGroupBy]
  ) {
    documentAggregationNew3(
      businessId: $businessId
      fromDate: $fromDate
      toDate: $toDate
      recorded: $recorded
      groupBy: $groupBy
    ) {
      nodes {
        count
        type
        supplier {
          name
          id
        }
        templateId
      }
    }
  }
`;

export function useDocumentsAggregationByStructureToken(variables) {
  const { error } = useNotification();

  const { result, loading, onError } = useQuery(DOCUMENTS_AGGREGATION_BY_STRUCTURE_TOKEN, variables);
  const aggregationConnection = useResult(result, []);

  const totalCount = computed(() => aggregationConnection.value.totalCount);

  const aggregation = computed(() => aggregationConnection.value.nodes);

  onError((err) => {
    console.error('document - useDocumentsAggregation', err);
    error();
  });
  return {
    aggregation,
    totalCount,
    loading,
  };
}

const DOCUMENTS_AGGREGATION_BY_STRUCTURE_TOKEN = gql`
  query documentAggregationNew3(
    $businessId: ID
    $fromDate: ISODate
    $toDate: ISODate
    $recorded: Boolean
    $groupBy: [DocumentAggregationGroupBy]
  ) {
    documentAggregationNew3(
      businessId: $businessId
      fromDate: $fromDate
      toDate: $toDate
      recorded: $recorded
      groupBy: $groupBy
    ) {
      nodes {
        count
        structureToken
      }
    }
  }
`;
