export const DOCUMENT_TYPES = {
  DELIVERY_NOTE: 'deliveryNote',
  BILL_INVOICE: 'billInvoice',
  BILL_INVOICE_RECEIPT: 'billInvoiceReceipt',
  INVOICE: 'invoice',
  CONSOLIDATED_INVOICE: 'consolidatedInvoice',
  GOODS_RETURN_NOTE: 'goodsReturnNote',
  CREDIT_NOTE: 'creditNote',
  CONSOLIDATED_CREDIT_NOTE: 'consolidatedCreditNote',
  RECONCILIATION_STATEMENT: 'reconciliationStatement',
  AGED_DEBTORS_REPORT: 'agedDebtorsReport',
  RECEIPT: 'receipt',
  INVOICE_RECEIPT: 'invoiceReceipt',
  PURCHASE_ORDER: 'purchaseOrder',
  OTHER: 'other',
};

export const NOTE_DOCS = [DOCUMENT_TYPES.DELIVERY_NOTE, DOCUMENT_TYPES.GOODS_RETURN_NOTE];
export const INVOICE_DOCS = [DOCUMENT_TYPES.INVOICE, DOCUMENT_TYPES.CREDIT_NOTE, DOCUMENT_TYPES.BILL_INVOICE];
export const CONSOLIDATED_DOCS = [DOCUMENT_TYPES.CONSOLIDATED_INVOICE, DOCUMENT_TYPES.CONSOLIDATED_CREDIT_NOTE];
export const RECONCILIATION_DOCS = [DOCUMENT_TYPES.RECONCILIATION_STATEMENT, DOCUMENT_TYPES.AGED_DEBTORS_REPORT];
export const CREDIT_DOCS = [
  DOCUMENT_TYPES.GOODS_RETURN_NOTE,
  DOCUMENT_TYPES.CREDIT_NOTE,
  DOCUMENT_TYPES.CONSOLIDATED_CREDIT_NOTE,
];
export const DEBIT_DOCS = [DOCUMENT_TYPES.DELIVERY_NOTE, DOCUMENT_TYPES.INVOICE, DOCUMENT_TYPES.CONSOLIDATED_INVOICE];
export const RECEIPT_DOCS = [
  DOCUMENT_TYPES.RECEIPT,
  DOCUMENT_TYPES.INVOICE_RECEIPT,
  DOCUMENT_TYPES.BILL_INVOICE_RECEIPT,
];

export const DOCUMENT_FLAGS = Object.freeze({
  IS_MANUAL: 'isManual',
  HAS_DECLARATION: 'hasDeclaration',
  LOCALE: 'locale',
});

export const DOCUMENT_TASK_TYPE = {
  organize: 'organize',
  classify: 'upload',
  extract: 'record',
};
