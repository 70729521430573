import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';
import { ref } from 'vue';

import { useNotification, useLoading } from '@/modules/core';

export function useDocumentNew2(id) {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(DOCUMENT_QUERY_NEW2, { id }, () => ({ enabled: !!id.value }));

  const document = useResult(result);
  const variables = ref({ id });
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('useDocumentNew2', err);
    error();
  });

  return {
    document,
    refetch,
    loading,
    onError,
  };
}

const DOCUMENT_QUERY_NEW2 = gql`
  query documentNew2($id: ID!) {
    documentNew2(id: $id) {
      createdAt
      createdBy
      id
      templateId
      filePath
      filePathUrl
      businessId
      supplierId
      orderReference
      deliveryDate
      source
      issueDate
      fromEmail
      pageCount
      type
      documentNumber
      allocationNumber
      replicateOf
      structureToken
      structureParams {
        documentNumber
        allocationNumber
        issueDate
        orderReference
        deliveryDate
        netAmount
        openingBalance
        amountDue
        taxableAmount
        nonTaxableAmount
        taxRate
        taxAmount
        totalAmount
        discountRate
        discountAmount
        paymentDueDate
        paymentDate
        paidAmount
        paymentMethod
        referencesFromDate
        referencesToDate
        rounding {
          debit
        }
        items {
          name
          sku
          gtin
          price {
            includingTax
          }
          netPrice
          quantity
          quantityInPackage
          packageQuantity
          discountRate
          discountAmount
          totalDiscount
          totalTax
          totalDeposits
          totalPackages
          totalAmount {
            includingTax
            includingDiscount
          }
          reference
        }
        references {
          documentNumber
          issueDate
          orderReference
          deliveryDate
          netAmount
          totalAmount
          paymentDueDate
          debitAmount
          creditAmount
          balance {
            debit
          }
          items {
            name
            sku
            gtin
            price {
              includingTax
            }
            netPrice
            quantity
            quantityInPackage
            packageQuantity
            discountRate
            discountAmount
            totalDiscount
            totalTax
            totalDeposits
            totalPackages
            totalAmount {
              includingTax
              includingDiscount
            }
          }
        }
        generalCharges {
          name
          amount
        }
      }
      recorded
      discountRate
      discountAmount
      rounding
      netAmount
      taxableAmount
      nonTaxableAmount
      taxRate
      taxAmount
      totalAmount
      paymentDueDate
      generalCharges {
        name
        amount
      }
      items {
        sku
        gtin
        name
        quantity
        packageQuantity
        quantityInPackage
        price
        netPrice
        discountRate
        discountAmount
        totalDiscount
        totalTax
        totalPackages
        totalDeposits
        totalAmount
        productId
      }
      references {
        documentNumber
        orderReference
        deliveryDate
        netAmount
        totalAmount
        issueDate
        items {
          sku
          gtin
          name
          quantity
          packageQuantity
          quantityInPackage
          price
          netPrice
          discountRate
          discountAmount
          totalDiscount
          totalTax
          totalPackages
          totalDeposits
          totalAmount
          productId
        }
      }
      ocr {
        abby {
          status
          originalPath
          originalPathUrl
          correctedPath
          correctedPathUrl
        }
        google {
          status
          generalPathUrl
        }
      }
    }
  }
`;
