export default class Rect {
  constructor(topLeft, bottomRight) {
    this.topLeft = topLeft;
    this.bottomRight = bottomRight;
  }

  static createFromCoordinates(c1, c2) {
    return new this(
      { x: Math.min(c1.x, c2.x), y: Math.min(c1.y, c2.y) },
      { x: Math.max(c1.x, c2.x), y: Math.max(c1.y, c2.y) }
    );
  }

  includes({ x, y }) {
    return x >= this.left && x <= this.right && y >= this.top && y <= this.bottom;
  }

  map(callback) {
    return new this.constructor(callback(this.topLeft), callback(this.bottomRight));
  }

  get width() {
    return this.bottomRight.x - this.topLeft.x;
  }

  get height() {
    return this.bottomRight.y - this.topLeft.y;
  }

  get left() {
    return this.topLeft.x;
  }

  get right() {
    return this.bottomRight.x;
  }

  get top() {
    return this.topLeft.y;
  }

  get bottom() {
    return this.bottomRight.y;
  }
}
