import { gql } from '@apollo/client/core';
import { computed, ref } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function useUploadedDocuments(businessId) {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(UPLOADED_DOCUMENTS_QUERY, { businessId }, () => ({
    enabled: !!businessId.value,
  }));
  const documentConnection = useResult(result, { nodes: [] });
  const variables = ref({ businessId });
  const loading = useLoading(queryLoading, variables);

  const documents = computed(() => documentConnection.value.nodes);

  onError((params) => {
    console.error('document - useUploadedDocuments', params);
    error();
  });
  return {
    documents,
    refetch,
    loading,
  };
}

const UPLOADED_DOCUMENTS_QUERY = gql`
  query documentsNew2($businessId: ID!) {
    documentsNew2(businessId: $businessId, recorded: false) {
      nodes {
        id
        issueDate
        createdBy
        documentNumber
        fromEmail
        source
        filePath
        type
        supplier {
          id
          name
          number
        }
      }
    }
  }
`;
