import { gql } from '@apollo/client/core';
import { computed, ref } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function useClassificationDocuments(businessId) {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(
    CLASSIFICATION_DOCUMENTS_QUERY,
    { businessId: businessId.value },
    {
      enabled: !!businessId.value,
      pollInterval: 10 * 60 * 1000,
    }
  );
  const documentConnection = useResult(result, { nodes: [] });
  const variables = ref({ businessId });
  const loading = useLoading(queryLoading, variables);
  const documents = computed(() => documentConnection.value.nodes);
  onError((params) => {
    console.error('document - useClassificationDocuments', params);
    error();
  });
  return {
    documents,
    refetch,
    loading,
  };
}

const CLASSIFICATION_DOCUMENTS_QUERY = gql`
  query documentsNew2($businessId: ID!) {
    documentsNew2(businessId: $businessId, recorded: false) {
      nodes {
        id
        issueDate
        createdBy
        createdAt
        documentNumber
        filePath
        pageCount
        fromEmail
        filePathUrl
        replicateOf
        isManual
        hasDeclaration
        locale
        source
        type
        supplier {
          id
          name
          number
        }
      }
    }
  }
`;
