import apiConnection from '@/imports/api/api_connection';
import { apolloClient } from '@/plugins/apollo-client';
import {
  QUERY_DOCUMENT_FILE_UPLOAD,
  MUTATION_CREATE_DOCUMENT,
  MUTATION_UPDATE_DOCUMENT,
  MUTATION_DELETE_DOCUMENT,
  MUTATION_RECORD_DOCUMENT,
  MUTATION_SPLIT_DOCUMENT,
  MUTATION_DUPLICATE_DOCUMENT,
  MUTATION_MERGE_DOCUMENTS,
  MUTATION_DERIVE_DOCUMENTS,
  MUTATION_ROTATE_DOCUMENT,
  MUTATION_RUN_OCR_ON_DOCUMENT,
} from './documents-queries';

export const getDocumentFileUpload = (variables) =>
  apolloClient.query({ query: QUERY_DOCUMENT_FILE_UPLOAD, variables }).then((result) => result.data.documentFileUpload);
export const createDocument = (variables) => apolloClient.mutate({ mutation: MUTATION_CREATE_DOCUMENT, variables });
export const updateDocument = (variables) => apolloClient.mutate({ mutation: MUTATION_UPDATE_DOCUMENT, variables });
export const deleteDocument = (variables) => apolloClient.mutate({ mutation: MUTATION_DELETE_DOCUMENT, variables });
export const recordDocument = (variables) => apolloClient.mutate({ mutation: MUTATION_RECORD_DOCUMENT, variables });
export const requireReviewOnTask = (data) => apiConnection.callPromise('tasks.requireReview', data);
export const splitDocument = (variables) => apolloClient.mutate({ mutation: MUTATION_SPLIT_DOCUMENT, variables });
export const mergeDocuments = (variables) => apolloClient.mutate({ mutation: MUTATION_MERGE_DOCUMENTS, variables });
export const deriveDocuments = (variables) => apolloClient.mutate({ mutation: MUTATION_DERIVE_DOCUMENTS, variables });
export const duplicateDocument = (variables) =>
  apolloClient.mutate({ mutation: MUTATION_DUPLICATE_DOCUMENT, variables });
export const rotateDocument = (variables) => apolloClient.mutate({ mutation: MUTATION_ROTATE_DOCUMENT, variables });
export const runOcrOnDocument = (variables) =>
  apolloClient.mutate({ mutation: MUTATION_RUN_OCR_ON_DOCUMENT, variables });
