import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export const useLast12Documents = (variables) => {
  const { error } = useNotification();
  const { result, loading, onError } = useQuery(DOCUMENTS_QUERY, variables, () => ({
    enabled: !!variables.value.businessId && !!variables.value.supplierId && !!variables.value.types,
  }));
  const documentConnection = useResult(result, { nodes: [] });
  const totalCount = computed(() => documentConnection.value.totalCount);

  const documents = computed(() => documentConnection.value.nodes);

  onError((err) => {
    console.error('useLast12Documents', err);
    error();
  });
  return {
    documents,
    loading,
    totalCount,
  };
};

const DOCUMENTS_QUERY = gql`
  query last12Documents($businessId: ID!, $supplierId: ID, $types: [DocumentType]) {
    documentsNew2(
      businessId: $businessId
      supplierId: $supplierId
      types: $types
      recorded: true
      pagination: { limit: 12 }
      sort: { sortBy: issueDate, orderBy: desc }
    ) {
      nodes {
        id
        issueDate
      }
    }
  }
`;
