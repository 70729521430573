export const ANALYTICS_ENUMS = Object.freeze({
  DOMAIN: 'Document',
  SOURCE_PAGE: {
    ORGANIZATION: 'organization',
    CLASSIFICATION: 'classify',
    RECORD: 'record',
  },
  PAGE_SECTION: {
    HEADER: 'header',
    BODY: 'body',
    TEMPLATE: 'template',
  },
  OUTCOME_TYPE: {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
  },
  MODAL_TYPE: {
    WARNING: 'warning',
  },
  MODAL_DESCRIPTION: {
    ISSUE_DATE_YEAR_AGO: 'issue_date_year_ago',
    ISSUE_DATE_MONTH_AGO: 'issue_date_month_ago',
    WRONG_BUSINESS: 'wrong_business',
  },
  USER_ACTION_RESULT: {
    CANCEL: 'cancel',
    SAVE: 'save',
    CLOSE: 'close',
  },
  MODAL_INTERACTION_VALUE: {
    VALID: 'valid',
    INVALID: 'invalid',
  },
  TRIGGER_ACTION: {
    COMPLETE_BUTTON_CLICKED: 'complete_button_clicked',
  },
});
